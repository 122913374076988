/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "react-error-boundary";
import "react-datepicker/dist/react-datepicker.css";
import ErrorFallback from "./pages/ErrorFallback";
import PrivateRoute from "./shared/Authorization/PrivateRoute";
import { PERMISSIONS } from "./shared/permissions";
// import SideBar from "./components/SideBars";
import "./app.css";
import { disableAuditorRoutes, enableLocalStorageValue } from "./utils/utils";
import AboutUs from "./pages/AboutUs/AboutUs";
import { useAuthUser } from "./shared/Context/UserContext";

const NotFound = lazy(
  () => /* webpackChunkName: "Farm-Calculator" */ import("./pages/NotFound")
);
const FarmCalculator = lazy(
  () =>
    /* webpackChunkName: "Farm-Calculator" */ import("./pages/FarmCalculator")
);
const FeedCalculator = lazy(
  () =>
    /* webpackChunkName: "Feed-Calculator" */ import("./pages/FeedCalaculator")
);
const Login = lazy(
  () => /* webpackChunkName: "Login" */ import("./pages/Login")
);
const SignUp = lazy(
  () => /* webpackChunkName: "Sign up" */ import("./pages/SignUp")
);
const ResetPassword = lazy(
  () => /* webpackChunkName: "ResetPassword" */ import("./pages/ResetPassword")
);
const Loader = lazy(
  () => /* webpackChunkName: "Loader" */ import("./components/Loader/Loader")
);
const MillingInformation = lazy(
  () =>
    /* webpackChunkName: "TellUsABout" */ import("./pages/MillingInformation")
);
const AllUsers = lazy(
  () => /* webpackChunkName: ""All users" */ import("./pages/Admin/AllUser/")
);
const EditAdminUser = lazy(
  () =>
    /* webpackChunkName: "Edit-Admin-User" */ import(
    "./pages/Admin/EditAdminUser"
  )
);
const SaveModals = lazy(
  () => /* webpackChunkName: "SaveModals" */ import("./pages/SaveModals")
);
const Inputs = lazy(
  () => /* webpackChunkName: "Inputs" */ import("./pages/Admin/Inputs")
);
const UserProfile = lazy(
  () =>
    /* webpackChunkName: " UserProfile" */ import(
    "./pages/UserProfile/UserProfile"
  )
);

const Landing = lazy(
  () => /* webpackChunkName: ""All users" */ import("./pages/Landing")
);

const App = () => {
  const { isAuditor } = useAuthUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const showReactQueryDevTools = searchParams.get("dev-tools");

  if (disableAuditorRoutes.includes(location.pathname)) {
    localStorage.removeItem("Auditor");
  }

  if (!enableLocalStorageValue.includes(location.pathname)) {
    localStorage.removeItem("data");
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} key={location?.pathname}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route caseSensitive path="/login" element={<Login />} />
            <Route caseSensitive path="/signup" element={<SignUp />} />
            <Route caseSensitive path="/about" element={<AboutUs />} />
            <Route
              caseSensitive
              path="/reset-password"
              element={<ResetPassword />}
            />
            <Route
              path="/site-information"
              element={
                <PrivateRoute
                  permission={PERMISSIONS.MILLING_INFORMATION_CANVIEW}
                >
                  <MillingInformation />
                </PrivateRoute>
              }
            />
            <Route
              path="/farm-calculator"
              element={
                <PrivateRoute permission={PERMISSIONS.FARM_CALCULATOR_CANVIEW}>
                  <FarmCalculator />
                </PrivateRoute>
              }
            />

            <Route
              path="/feed-calculator"
              element={
                <PrivateRoute permission={PERMISSIONS.FEED_CALCULATOR_CANVIEW}>
                  <FeedCalculator />
                </PrivateRoute>
              }
            />

            <Route
              path={isAuditor ? "/shared-models" : "/save-models"}
              element={
                <PrivateRoute permission={PERMISSIONS.SAVE_MODAL_CANVIEW}>
                  <SaveModals />
                </PrivateRoute>
              }
            />
            <Route
              path="/about-us"
              element={
                <PrivateRoute permission={PERMISSIONS.USER_PROFILE_CANVIEW}>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute permission={PERMISSIONS.ADMIN_PANEL_CANVIEW}>
                  <AllUsers />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/inputs"
              element={
                <PrivateRoute permission={PERMISSIONS.ADMIN_PANEL_CANVIEW}>
                  <Inputs />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users/editUser/:id"
              element={
                <PrivateRoute permission={PERMISSIONS.ADMIN_PANEL_CANVIEW}>
                  <EditAdminUser />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute permission={PERMISSIONS.USER_PROFILE_CANVIEW}>
                  <NotFound />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
        {showReactQueryDevTools && <ReactQueryDevtools />}
      </ErrorBoundary>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="customTostMsg"
      />
    </>
  );
};

export default App;
