import logoImg from "./../../assets/asc_logo.svg";
import IllustrationFallback from "../../assets/customIcons/IllustrationFallback";

const ErrorFallback = () => {
  return (
    <>
      <div className="flex flex-col w-full h-full">
        <header className="relative w-full">
          <nav className="py-4 px-6 headerBar">
            <div className="flex flex-wrap justify-between items-center">
              <img src={logoImg} alt="asc-Logo" />
            </div>
          </nav>
        </header>
        <div className="curvedBoxBg flex h-full w-full ">
          <div className="bg-white rounded-tl-6xl w-full rounded-tr-6xl">
            <div className="flex flex-col h-full w-full justify-center items-center">
              <IllustrationFallback />
              <div className="text-3xl font-bold text-silver-800 mt-8">
                Something went wrong
              </div>
              <div className="mt-4 text-base text-silver-500 text-center w-80 mx-auto">
              Try refreshing the page, or try again later.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorFallback;
