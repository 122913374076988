import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import logoImg from "./../../assets/asc_logo.svg";
import AboutContent from "../../components/AboutContent/AboutContent";
import { useAuthUser } from "../../shared/Context/UserContext";

const AboutUs = () => {
  const navigate = useNavigate();
  const { isAuditor } = useAuthUser();

  useEffect(() => {
    window.history.pushState(null, "", "/about");
    window.onpopstate = function () {
      history.go(1);
    };
  });

  const onSubmitHandler = () => {
    if (isAuditor) {
      navigate("/shared-models");
    } else {
      navigate("/site-information");
    }
  };

  return (
    <>
      <div className="AboutUsPage">
        <header className="relative" id="topBar">
          <nav className="py-4 px-6 headerBar">
            <div className="flex flex-wrap justify-between items-center">
              <img src={logoImg} alt="asc-logo" />
            </div>
          </nav>
        </header>
        <div className="fsm:p-8 p-4 h-full">
          <div className="xl:w-4/6 w-full mx-auto xl:mt-0 mt-8">
            <AboutContent />
            <button
              type="submit"
              onClick={() => onSubmitHandler()}
              className="my-8 text-white text-base rounded-full p-2.5 font-medium cursor-pointer px-5 py-2.5  bg-primary-500 hover:bg-primary-600"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
