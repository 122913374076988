type Environment = {
  [key: string]: {
    BASE_PATH: string;
    API_URL: string;
    BASE_URL: string;
  };
};

console.log("=========Test==============");
const environment: Environment = {
  local: {
    API_URL: "http://localhost:4001",
    BASE_PATH: "/",
    BASE_URL: "http://localhost:3000",
  },
  dev: {
    API_URL: "https://ghg-test.asc-aqua.org",
    BASE_PATH: "/",
    BASE_URL: "https://ghg-test.asc-aqua.org",
  },
  production: {
    API_URL: "https://ghg.asc-aqua.org",
    BASE_PATH: "/",
    BASE_URL: "https://ghg.asc-aqua.org",
  },
};

console.log(process.env);
const config = environment[process.env.REACT_APP_ENV ?? ""] || environment.local;

//for testing purpose --- verifing
console.log(config);
export default {
  // Add common config values here
  ...config,
};
