import config from "../config";
import axios from "axios";
import handleAPIError from "./handleAPIError";
import { RequestTypes } from "./type";
const callAPI = async ({
  url,
  method = "GET",
  query = {},
  data,
  contentType = "application/json",
  header = {},
}: RequestTypes) => {
  const BASE_URL = config.API_URL;
  try {
    const headers: any = {
      ...header,
      "Content-Type": `${contentType}`,
    };
    const requestOptions: Object = {
      data: data,
      headers,
      url: `${BASE_URL}${url}`,
      // url: `${url}`,
      method,
      params: query,
      withCredentials: true,
    };
    const responseData = await axios(requestOptions);
    if (responseData.data.message === "Success") {
      if (Object.keys(responseData.data).length > 2) {
        return responseData.data;
      } else {
        return responseData.data.data;
      }
    } else {
      return responseData;
    }
  } catch (error: any) {
    if (url !== "/v1/api/getUserProfile") {
      handleAPIError(error);
    }
    throw {
      name: error?.response?.data?.error?.name,
      code: error?.response?.data?.error?.code,
      message: error?.response?.data?.error?.message,
    };
  }
};
export default callAPI;
