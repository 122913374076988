const IllustrationFallback = () => (
  <svg
    width="400"
    height="400"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5170_218112)">
      <path
        d="M198.038 64.6259C198.496 64.6259 198.753 64.3021 198.791 63.9497C198.857 63.6164 198.743 63.2641 198.381 63.1117C198.334 63.0831 198.267 63.0641 198.21 63.0546C195.734 62.4546 193.772 59.4549 191.191 59.4168C188.992 59.3883 187.354 59.0073 186.287 56.8933C185.811 55.9505 185.335 55.122 184.611 54.3697C183.297 52.9889 181.545 52.8079 179.793 53.065C178.526 53.246 177.326 53.2174 176.06 53.2936C175.355 53.3317 173.888 50.3034 173.565 49.7796C170.851 45.3896 168.251 40.6853 162.556 40.0853C157.699 39.5711 153.043 41.4662 149.691 44.923C147.196 42.5994 145.101 40.1044 141.282 41.9613C139.187 42.9803 137.901 44.3611 136.892 46.4466C136.32 47.6275 135.835 48.6845 134.921 49.6558C133.702 50.951 131.273 50.1987 129.759 50.1796C127.778 50.1606 126.483 51.7604 125.36 53.1603C123.988 54.8744 122.722 54.9982 120.693 54.3602C120.055 54.1602 118.989 54.1411 118.389 54.4649C114.723 56.4362 113.865 62.6451 109.094 63.0451C108.085 63.1308 108.056 64.6354 109.094 64.6354H198.048L198.038 64.6259Z"
        fill="#A5F2F0"
      />
      <path
        d="M271.945 55.5557H328.683C329.349 55.5557 329.33 54.632 328.683 54.5749C325.626 54.3273 325.064 50.4991 322.722 49.2897C322.341 49.0897 321.655 49.0992 321.246 49.223C319.95 49.6135 319.131 49.5373 318.255 48.4802C317.541 47.6232 316.703 46.6328 315.427 46.6423C314.456 46.6423 312.894 47.1185 312.113 46.3185C311.523 45.7186 311.218 45.071 310.847 44.3378C310.199 43.0522 309.37 42.2046 308.028 41.5761C305.571 40.4334 304.228 41.9666 302.628 43.4045C300.476 41.2714 297.476 40.1096 294.362 40.4239C290.706 40.7952 289.039 43.6997 287.287 46.4042C287.077 46.728 286.135 48.5945 285.687 48.5659C284.868 48.5183 284.106 48.5374 283.287 48.4231C282.164 48.2707 281.04 48.3755 280.192 49.223C279.726 49.6896 279.421 50.2039 279.116 50.7848C278.44 52.0894 277.383 52.318 275.964 52.337C274.307 52.3656 273.05 54.2035 271.46 54.5749C271.422 54.5749 271.384 54.5939 271.346 54.613C271.107 54.7082 271.041 54.9272 271.079 55.1272C271.107 55.3462 271.269 55.5462 271.565 55.5462H271.945V55.5557Z"
        fill="#A5F2F0"
      />
      <path
        d="M90.5724 187.02C90.5724 187.02 90.5152 187.011 90.4866 187.001C88.6868 186.725 86.906 186.373 85.23 185.63C83.7825 184.992 82.8969 183.611 81.4304 182.973C80.3828 182.526 78.9925 182.868 78.3735 181.802C77.6022 180.488 77.3546 178.907 76.6499 177.555C75.4119 175.203 72.7074 174.565 70.2886 174.926C67.3746 175.355 65.689 177.698 63.9939 179.85C62.8607 178.031 61.2418 176.698 58.9087 177.126C55.9471 177.669 55.1376 179.869 53.9854 182.259C51.0333 180.135 47.2622 180.688 46.3099 184.525C44.2434 184.392 41.4532 185.202 41.3104 187.535C41.2913 187.83 41.5675 188.068 41.8437 188.068H90.4295C90.7533 188.068 90.9247 187.83 90.9437 187.582C91.0009 187.335 90.8961 187.068 90.5724 187.02Z"
        fill="#A5F2F0"
      />
      <path
        d="M296.753 138.425C296.781 138.825 297.057 139.196 297.572 139.196H374.298C374.736 139.196 375.174 138.806 375.145 138.348C374.926 134.654 370.517 133.377 367.251 133.587C365.746 127.53 359.785 126.654 355.128 130.016C353.319 126.245 352.043 122.769 347.357 121.912C343.672 121.236 341.101 123.35 339.32 126.216C336.644 122.826 333.978 119.122 329.388 118.446C325.579 117.884 321.303 118.893 319.351 122.598C318.227 124.731 317.836 127.235 316.617 129.311C315.627 130.987 313.437 130.444 311.78 131.159C309.466 132.159 308.066 134.349 305.78 135.349C303.133 136.511 300.314 137.082 297.467 137.51C297.419 137.51 297.372 137.52 297.324 137.529C296.81 137.615 296.648 138.025 296.743 138.425H296.753Z"
        fill="#A5F2F0"
      />
      <path
        d="M400 267.795C400 295.726 360.918 288.974 358.88 308.163C356.985 325.999 384.002 325.132 387.83 337.922C394.924 361.615 351.747 367.576 339.606 368.528C308.056 371.014 280.297 369.88 256.071 367.671C248.386 367.119 223.369 367.624 222.398 359.405C221.217 349.492 236.035 352.777 236.501 346.178C237.092 337.912 212.389 337.903 199.286 338.569C174.517 339.826 159.175 338.464 135.54 340.112C123.131 340.664 83.5444 342.312 84.1349 324.675C84.3158 319.142 94.0577 316.686 94.7719 312.553C96.448 302.82 65.8223 303.192 58.1373 303.734C38.044 304.839 2.45693 301.668 0.238095 286.107C-3.10444 262.653 30.3686 260.358 41.5961 259.805C67.0032 259.805 91.2294 259.805 117.227 257.605C123.017 257.11 129.045 257.453 127.864 250.844C126.683 246.435 118.408 244.788 117.817 240.378C115.456 222.199 180.221 219.485 214.122 222.599C250.414 225.932 270.65 227.704 255.88 242.578C243.748 254.806 400 235.969 400 267.785V267.795Z"
        fill="#56D2CE"
      />
      <path
        d="M325.16 292.991C322.055 273.689 301.59 256.005 282.068 255.824C261.937 255.643 243.472 273.784 237.739 291.973C237.311 293.344 237.987 295.134 239.301 295.791C252.957 302.657 268.432 304.79 283.592 304.705C296.934 304.628 311.98 304.114 323.607 296.81C324.779 296.077 325.226 294.982 325.188 293.906C325.217 293.61 325.217 293.315 325.16 292.991Z"
        fill="#E9A83F"
      />
      <path
        d="M259.166 276.115C259.166 276.639 258.623 277.182 258.099 277.182C257.575 277.182 256.918 276.639 256.918 276.115C256.918 275.592 257.575 275.049 258.099 275.049C258.623 275.049 259.166 275.592 259.166 276.115Z"
        fill="#B77A49"
      />
      <path
        d="M266.051 277.184C266.051 277.708 265.508 278.25 264.984 278.25C264.46 278.25 263.794 277.708 263.794 277.184C263.794 276.66 264.451 276.117 264.984 276.117C265.517 276.117 266.051 276.66 266.051 277.184Z"
        fill="#B77A49"
      />
      <path
        d="M316.027 291.096C316.027 291.62 315.484 292.162 314.96 292.162C314.437 292.162 313.78 291.62 313.78 291.096C313.78 290.572 314.437 290.029 314.96 290.029C315.484 290.029 316.027 290.572 316.027 291.096Z"
        fill="#B77A49"
      />
      <path
        d="M313.77 273.641C313.77 274.165 313.227 274.707 312.703 274.707C312.18 274.707 311.513 274.165 311.513 273.641C311.513 273.117 312.17 272.574 312.703 272.574C313.237 272.574 313.77 273.117 313.77 273.641Z"
        fill="#B77A49"
      />
      <path
        d="M244.443 291.887C244.443 292.411 243.901 292.953 243.377 292.953C242.853 292.953 242.196 292.411 242.196 291.887C242.196 291.363 242.853 290.82 243.377 290.82C243.901 290.82 244.443 291.363 244.443 291.887Z"
        fill="#B77A49"
      />
      <path
        d="M304.295 298.076C304.295 298.6 303.752 299.143 303.228 299.143C302.704 299.143 302.038 298.6 302.038 298.076C302.038 297.553 302.695 297.01 303.228 297.01C303.761 297.01 304.295 297.553 304.295 298.076Z"
        fill="#B77A49"
      />
      <path
        d="M266.613 293.238C266.613 293.762 266.07 294.305 265.546 294.305C265.022 294.305 264.356 293.762 264.356 293.238C264.356 292.715 265.013 292.172 265.546 292.172C266.079 292.172 266.613 292.715 266.613 293.238Z"
        fill="#B77A49"
      />
      <path
        d="M268.765 270.545C268.765 270.802 268.489 271.078 268.231 271.078C267.974 271.078 267.641 270.802 267.641 270.545C267.641 270.288 267.974 270.012 268.231 270.012C268.489 270.012 268.765 270.278 268.765 270.545Z"
        fill="#B77A49"
      />
      <path
        d="M262.213 273.107C262.213 273.365 261.937 273.641 261.68 273.641C261.423 273.641 261.089 273.365 261.089 273.107C261.089 272.85 261.423 272.574 261.68 272.574C261.937 272.574 262.213 272.841 262.213 273.107Z"
        fill="#B77A49"
      />
      <path
        d="M303.733 272.574C303.733 272.841 303.457 273.108 303.2 273.108C302.943 273.108 302.609 272.831 302.609 272.574C302.609 272.317 302.943 272.041 303.2 272.041C303.457 272.041 303.733 272.317 303.733 272.574Z"
        fill="#B77A49"
      />
      <path
        d="M281.411 292.695C281.411 292.953 281.135 293.229 280.878 293.229C280.621 293.229 280.278 292.953 280.278 292.695C280.278 292.438 280.611 292.162 280.878 292.162C281.145 292.162 281.411 292.429 281.411 292.695Z"
        fill="#B77A49"
      />
      <path
        d="M253.614 290.029C253.614 290.286 253.338 290.563 253.081 290.563C252.824 290.563 252.49 290.286 252.49 290.029C252.49 289.772 252.824 289.496 253.081 289.496C253.338 289.496 253.614 289.763 253.614 290.029Z"
        fill="#B77A49"
      />
      <path
        d="M300.686 264.908C300.686 265.165 300.409 265.442 300.152 265.442C299.895 265.442 299.562 265.165 299.562 264.908C299.562 264.651 299.895 264.375 300.152 264.375C300.409 264.375 300.686 264.651 300.686 264.908Z"
        fill="#B77A49"
      />
      <path
        d="M274.593 302.219C274.593 302.485 274.317 302.752 274.06 302.752C273.802 302.752 273.469 302.476 273.469 302.219C273.469 301.962 273.802 301.686 274.06 301.686C274.317 301.686 274.593 301.952 274.593 302.219Z"
        fill="#B77A49"
      />
      <path
        d="M250.9 290.563C250.9 290.829 250.624 291.096 250.367 291.096C250.109 291.096 249.776 290.82 249.776 290.563C249.776 290.305 250.109 290.029 250.367 290.029C250.624 290.029 250.9 290.296 250.9 290.563Z"
        fill="#B77A49"
      />
      <path
        d="M307.685 282.563C307.685 282.82 307.409 283.096 307.152 283.096C306.894 283.096 306.561 282.82 306.561 282.563C306.561 282.305 306.894 282.029 307.152 282.029C307.409 282.029 307.685 282.305 307.685 282.563Z"
        fill="#B77A49"
      />
      <path
        d="M289.258 283.096C289.258 283.353 288.982 283.629 288.725 283.629C288.468 283.629 288.134 283.353 288.134 283.096C288.134 282.839 288.468 282.562 288.725 282.562C288.982 282.562 289.258 282.829 289.258 283.096Z"
        fill="#B77A49"
      />
      <path
        d="M277.897 280.277C277.897 280.535 277.621 280.811 277.364 280.811C277.107 280.811 276.774 280.535 276.774 280.277C276.774 280.02 277.107 279.744 277.364 279.744C277.621 279.744 277.897 280.011 277.897 280.277Z"
        fill="#B77A49"
      />
      <path
        d="M285.878 292.162C285.878 292.429 285.601 292.695 285.344 292.695C285.087 292.695 284.754 292.419 284.754 292.162C284.754 291.905 285.078 291.629 285.344 291.629C285.611 291.629 285.878 291.905 285.878 292.162Z"
        fill="#B77A49"
      />
      <path
        d="M281.973 261.289C281.973 261.556 281.697 261.822 281.44 261.822C281.183 261.822 280.849 261.556 280.849 261.289C280.849 261.022 281.183 260.756 281.44 260.756C281.697 260.756 281.973 261.032 281.973 261.289Z"
        fill="#B77A49"
      />
      <path
        d="M267.174 262.365C267.174 262.622 266.898 262.899 266.641 262.899C266.384 262.899 266.051 262.622 266.051 262.365C266.051 262.108 266.384 261.832 266.641 261.832C266.898 261.832 267.174 262.108 267.174 262.365Z"
        fill="#B77A49"
      />
      <path
        d="M317.608 221.903C316.865 221.151 316.132 220.399 315.389 219.646C315.122 219.38 314.751 219.294 314.427 219.541C297.476 232.683 287.039 253.272 280.297 273.222C280.202 273.498 280.259 273.86 280.526 274.022C283.078 275.603 285.801 276.346 288.601 275.165C288.801 275.127 288.982 275.012 289.058 274.755C289.058 274.755 289.058 274.746 289.058 274.736C289.058 274.736 289.058 274.736 289.058 274.727C291.287 267.861 293.991 261.147 296.905 254.548C301.866 243.301 307.828 230.845 317.494 222.865C317.76 222.646 317.865 222.179 317.598 221.903H317.608Z"
        fill="#865936"
      />
      <mask
        id="mask0_5170_218112"
        maskUnits="userSpaceOnUse"
        x="280"
        y="219"
        width="38"
        height="57"
      >
        <path
          d="M317.608 221.903C316.865 221.151 316.132 220.399 315.389 219.646C315.122 219.38 314.751 219.294 314.427 219.541C297.476 232.683 287.039 253.272 280.297 273.222C280.202 273.498 280.259 273.86 280.526 274.022C283.078 275.603 285.801 276.346 288.601 275.165C288.801 275.127 288.982 275.012 289.058 274.755C289.058 274.755 289.058 274.746 289.058 274.736C289.058 274.736 289.058 274.736 289.058 274.727C291.287 267.861 293.991 261.147 296.905 254.548C301.866 243.301 307.828 230.845 317.494 222.865C317.76 222.646 317.865 222.179 317.598 221.903H317.608Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5170_218112)">
        <path
          d="M281.068 263.547C283.43 267.956 286.144 272.156 289.039 276.222C289.239 276.507 289.668 276.165 289.468 275.879C286.592 271.841 283.906 267.689 281.564 263.318C281.402 263.014 280.897 263.233 281.068 263.537V263.547Z"
          fill="#946641"
        />
        <path
          d="M283.611 258.88C285.887 264.499 289.801 269.146 292.934 274.279C293.115 274.584 293.543 274.241 293.363 273.936C290.258 268.851 286.373 264.232 284.116 258.661C283.982 258.337 283.487 258.556 283.621 258.88H283.611Z"
          fill="#946641"
        />
        <path
          d="M286.106 253.348C288.734 257.329 290.801 261.633 293.105 265.804C293.277 266.109 293.772 265.89 293.6 265.585C291.277 261.376 289.191 257.024 286.534 253.006C286.344 252.71 285.915 253.053 286.106 253.348Z"
          fill="#946641"
        />
        <path
          d="M289.906 248.823C292.534 252.575 294.677 256.613 296.981 260.556C297.153 260.851 297.657 260.641 297.486 260.337C295.153 256.356 292.982 252.271 290.334 248.481C290.134 248.195 289.706 248.538 289.906 248.823Z"
          fill="#946641"
        />
        <path
          d="M293.496 239.273C295.21 244.034 297.486 248.529 299.848 252.995C300.01 253.31 300.514 253.09 300.343 252.776C297.981 248.31 295.705 243.815 294.001 239.054C293.886 238.72 293.382 238.949 293.505 239.282L293.496 239.273Z"
          fill="#946641"
        />
        <path
          d="M298.352 231.93C299.952 236.863 302.219 241.51 304.457 246.176C304.609 246.49 305.114 246.271 304.952 245.957C302.714 241.291 300.448 236.634 298.848 231.711C298.733 231.377 298.238 231.596 298.343 231.93H298.352Z"
          fill="#946641"
        />
        <path
          d="M302.838 227.846C304.047 231.893 305.104 235.969 306.818 239.835C306.961 240.159 307.457 239.94 307.314 239.616C305.6 235.75 304.542 231.664 303.333 227.627C303.228 227.293 302.733 227.512 302.828 227.846H302.838Z"
          fill="#946641"
        />
        <path
          d="M306.485 223.121C307.714 226.721 308.809 230.368 310.037 233.968C310.151 234.301 310.647 234.082 310.532 233.749C309.304 230.149 308.199 226.502 306.98 222.902C306.866 222.569 306.371 222.788 306.485 223.121Z"
          fill="#946641"
        />
        <path
          d="M311.132 219.246C312.113 222.151 313.084 225.065 313.999 227.998C314.103 228.331 314.599 228.112 314.494 227.779C313.58 224.846 312.608 221.932 311.618 219.027C311.504 218.694 311.008 218.913 311.123 219.246H311.132Z"
          fill="#946641"
        />
        <path
          d="M276.726 272.356C283.011 271.632 289.01 269.613 295.162 268.185C295.505 268.109 295.419 267.566 295.077 267.642C288.934 269.071 282.935 271.089 276.65 271.813C276.297 271.851 276.383 272.394 276.735 272.356H276.726Z"
          fill="#946641"
        />
        <path
          d="M276.469 266.461C283.611 264.804 290.477 262.29 297.419 259.976C297.753 259.862 297.524 259.367 297.191 259.471C290.296 261.776 283.468 264.271 276.374 265.918C276.031 265.995 276.117 266.537 276.459 266.461H276.469Z"
          fill="#946641"
        />
        <path
          d="M279.402 259.281C286.601 259.5 293.153 256.739 299.962 254.786C300.295 254.691 300.209 254.148 299.876 254.244C293.134 256.177 286.601 258.948 279.469 258.738C279.116 258.729 279.059 259.272 279.411 259.281H279.402Z"
          fill="#946641"
        />
        <path
          d="M283.24 253.462C290.077 252.148 296.8 250.405 303.561 248.767C303.904 248.681 303.819 248.148 303.476 248.224C296.715 249.853 289.982 251.595 283.154 252.919C282.811 252.986 282.897 253.528 283.24 253.462Z"
          fill="#946641"
        />
        <path
          d="M285.659 248.235C293.201 246.159 300.752 244.083 308.333 242.15C308.666 242.064 308.59 241.521 308.247 241.607C300.667 243.54 293.115 245.606 285.573 247.692C285.24 247.787 285.316 248.32 285.659 248.235Z"
          fill="#946641"
        />
        <path
          d="M290.315 241.053C296.2 240.024 302.057 238.844 307.923 237.701C308.266 237.634 308.19 237.091 307.837 237.158C301.971 238.301 296.115 239.472 290.23 240.51C289.887 240.567 289.963 241.11 290.315 241.053Z"
          fill="#946641"
        />
        <path
          d="M294.477 234.921C300.257 233.645 306.171 233.255 311.999 232.293C312.351 232.236 312.256 231.693 311.913 231.75C306.076 232.703 300.171 233.093 294.391 234.369C294.048 234.445 294.134 234.979 294.477 234.912V234.921Z"
          fill="#946641"
        />
        <path
          d="M299.714 228.368C304.962 228.368 310.199 227.921 315.446 227.73C315.799 227.721 315.703 227.178 315.361 227.188C310.161 227.369 304.99 227.826 299.781 227.816C299.429 227.816 299.372 228.359 299.714 228.359V228.368Z"
          fill="#946641"
        />
        <path
          d="M302.762 224.131C308.114 225.188 313.551 224.997 318.979 225.026C319.332 225.026 319.389 224.483 319.036 224.483C313.608 224.464 308.18 224.645 302.819 223.588C302.476 223.521 302.419 224.064 302.762 224.131Z"
          fill="#946641"
        />
      </g>
      <path
        d="M323.636 224.32C323.417 226.244 321.217 228.006 319.293 227.787C317.379 227.568 315.189 225.32 315.399 223.397C315.618 221.473 318.246 219.759 320.17 219.978C322.084 220.197 323.855 222.397 323.636 224.32Z"
        fill="#6B4C34"
      />
      <path
        d="M312.113 223.721C311.894 225.644 309.694 227.406 307.771 227.187C305.847 226.968 303.666 224.721 303.876 222.797C304.095 220.873 306.723 219.159 308.647 219.378C310.571 219.597 312.332 221.797 312.113 223.721Z"
        fill="#6B4C34"
      />
      <path
        d="M340.901 218.055C341.701 219.617 341.815 221.484 341.682 223.141C345.329 224.36 348.872 225.912 352.052 227.693C352.538 226.626 352.928 225.388 353.014 224.083C353.014 224.083 354.09 226.169 354.395 229.083C357.776 231.207 360.613 233.578 362.518 236.006C364.184 227.645 362.994 219.512 357.642 214.18C355.442 215.189 354.271 216.36 354.271 216.36C354.547 215.103 355.3 213.932 356.204 212.913C352.786 210.18 348.015 208.409 341.634 208.056C340.453 209.237 339.234 210.723 338.177 212.532C338.177 212.532 338.016 210.542 338.454 208.047C328.255 208.771 316.303 216.684 313.18 224.645C318.503 219.284 329.131 219.446 339.396 222.436C340.749 220.084 340.882 218.055 340.882 218.055H340.901Z"
        fill="#7A8A44"
      />
      <path
        d="M336.501 213.503C333.502 213.36 330.607 213.864 328.026 214.683C325.445 215.502 323.15 216.626 321.189 217.836C317.275 220.264 314.627 222.883 313.199 224.654C314.865 223.102 317.665 220.731 321.589 218.521C325.483 216.359 330.683 214.36 336.454 214.607C342.206 214.845 347.243 217.236 350.852 219.864C354.538 222.464 357.033 225.159 358.661 226.749C357.271 224.949 354.966 222.045 351.319 219.226C347.748 216.388 342.539 213.76 336.492 213.512L336.501 213.503Z"
        fill="#6C793C"
      />
      <path
        d="M343.472 206.322C344.986 207.541 345.853 209.265 346.358 210.874C350.957 210.836 355.576 211.141 359.871 211.779C359.994 210.617 359.947 209.312 359.537 208.055C359.537 208.055 361.575 209.684 363.07 212.331C367.727 213.236 371.869 214.54 374.983 216.216C373.565 207.77 369.013 200.475 360.861 197.18C358.775 198.856 357.909 200.342 357.909 200.342C357.728 199.066 358.118 197.713 358.737 196.447C353.795 194.98 347.7 194.876 340.358 196.628C339.492 198.132 338.701 199.932 338.225 201.989C338.225 201.989 337.254 200.161 336.768 197.656C325.531 201.684 315.151 213.064 314.761 221.616C318.665 214.807 330.731 211.474 343.51 210.941C344.11 208.274 343.463 206.313 343.463 206.313L343.472 206.322Z"
        fill="#7A8A44"
      />
      <path
        d="M336.701 203.466C333.254 204.313 330.188 205.732 327.598 207.351C324.998 208.97 322.855 210.779 321.122 212.56C317.665 216.141 315.703 219.474 314.78 221.616C316.056 219.607 318.284 216.445 321.846 213.065C325.398 209.741 330.483 206.151 337.092 204.504C343.691 202.847 350.319 203.456 355.433 204.751C360.623 205.999 364.499 207.732 366.975 208.703C364.689 207.456 360.947 205.465 355.709 203.999C350.557 202.494 343.634 201.713 336.701 203.456V203.466Z"
        fill="#6C793C"
      />
      <path
        d="M336.625 237.548C336.463 239.148 335.673 240.519 334.797 241.576C336.892 244.795 338.758 248.185 340.253 251.489C341.11 251.061 341.987 250.461 342.672 249.613C342.672 249.613 342.472 251.766 341.32 253.975C342.796 257.651 343.758 261.136 344.005 264.069C349.176 259.355 352.148 252.946 350.738 245.757C348.643 245.023 347.234 245.071 347.234 245.071C348.024 244.376 349.138 244.052 350.281 243.938C349.062 239.814 346.396 235.472 341.891 231.082C340.463 231.139 338.873 231.367 337.244 231.939C337.244 231.939 338.073 230.453 339.568 229.006C331.74 222.863 319.246 220.559 313.189 224.054C319.627 223.806 327.35 230.834 333.464 239.605C335.568 238.862 336.625 237.538 336.625 237.538V237.548Z"
        fill="#7A8A44"
      />
      <path
        d="M335.54 231.521C333.406 229.464 331.045 227.931 328.769 226.816C326.483 225.693 324.274 224.979 322.265 224.54C318.246 223.683 315.065 223.769 313.18 224.064C315.132 224.074 318.313 224.255 322.236 225.274C326.121 226.312 330.873 228.321 334.987 232.245C339.101 236.158 341.663 241.091 343.072 245.272C344.548 249.481 345.101 252.966 345.548 255.138C345.377 252.985 345.062 249.471 343.71 245.139C342.434 240.853 339.853 235.625 335.53 231.521H335.54Z"
        fill="#6C793C"
      />
      <path
        d="M288.154 218.045C287.135 219.283 286.716 220.817 286.582 222.188C282.821 222.969 279.107 224.035 275.707 225.302C275.412 224.388 275.221 223.35 275.336 222.264C275.336 222.264 273.955 223.921 273.193 226.302C269.546 227.844 266.394 229.625 264.137 231.511C263.832 224.521 266.289 217.893 272.374 213.827C274.364 214.789 275.326 215.827 275.326 215.827C275.26 214.779 274.707 213.76 273.983 212.865C277.764 210.817 282.725 209.656 289.02 209.76C289.991 210.808 290.944 212.103 291.696 213.655C291.696 213.655 292.172 212.017 292.134 209.941C302.009 211.16 312.446 218.407 314.246 225.159C309.885 220.417 299.457 219.893 288.925 221.74C287.973 219.721 288.163 218.036 288.163 218.036L288.154 218.045Z"
        fill="#7A8A44"
      />
      <path
        d="M293.191 214.56C296.153 214.626 298.905 215.217 301.295 216.045C303.695 216.874 305.761 217.94 307.485 219.055C310.923 221.293 313.104 223.616 314.227 225.159C312.846 223.778 310.475 221.654 306.99 219.588C303.514 217.569 298.743 215.607 293.058 215.465C287.382 215.303 282.078 216.969 278.126 218.912C274.107 220.826 271.231 222.892 269.384 224.102C271.031 222.702 273.745 220.455 277.774 218.359C281.716 216.245 287.239 214.388 293.201 214.56H293.191Z"
        fill="#6C793C"
      />
      <path
        d="M293.477 205.894C292.02 206.866 291.001 208.437 290.306 209.951C286.535 209.227 282.678 208.846 279.04 208.837C279.145 207.675 279.402 206.389 279.964 205.218C279.964 205.218 278.012 206.513 276.317 208.903C272.327 209.103 268.689 209.77 265.841 210.96C268.479 202.856 273.507 196.343 280.783 194.314C282.211 196.267 282.649 197.866 282.649 197.866C283.021 196.638 282.945 195.248 282.649 193.914C286.963 193.2 292.001 194 297.724 196.819C298.172 198.428 298.505 200.323 298.543 202.418C298.543 202.418 299.657 200.752 300.495 198.371C309.028 203.999 315.58 216.75 314.399 225.226C312.38 217.941 303.038 212.874 292.62 210.456C292.591 207.742 293.467 205.904 293.467 205.904L293.477 205.894Z"
        fill="#7A8A44"
      />
      <path
        d="M299.553 204.084C302.238 205.427 304.514 207.284 306.361 209.265C308.218 211.245 309.666 213.341 310.78 215.35C312.999 219.388 314.027 222.968 314.408 225.216C313.713 223.044 312.437 219.607 310.094 215.75C307.752 211.95 304.2 207.665 299.057 205.056C293.925 202.437 288.363 202.065 283.925 202.58C279.44 203.046 275.945 204.17 273.745 204.76C275.841 203.875 279.269 202.465 283.83 201.799C288.335 201.075 294.163 201.332 299.553 204.084Z"
        fill="#6C793C"
      />
      <path
        d="M309.856 196.524C308.352 195.972 306.809 196.095 305.495 196.419C303.476 193.153 301.2 190.02 298.857 187.258C299.591 186.677 300.495 186.144 301.524 185.906C301.524 185.906 299.514 185.163 297.067 185.23C294.382 182.325 291.648 179.954 289.134 178.468C295.467 175.878 302.428 175.983 308.285 180.344C308.085 182.535 307.466 183.773 307.466 183.773C308.409 183.363 309.152 182.506 309.732 181.535C312.923 184.401 315.713 188.668 317.808 194.6C317.179 195.857 316.313 197.181 315.141 198.39C315.141 198.39 316.808 198.295 318.713 197.571C321.008 207.228 317.941 219.407 312.342 223.321C315.199 217.665 312.075 207.723 306.733 198.457C308.266 196.895 309.875 196.524 309.875 196.524H309.856Z"
        fill="#7A8A44"
      />
      <path
        d="M314.808 200.096C315.77 202.895 316.179 205.667 316.246 208.181C316.313 210.704 316.046 212.999 315.618 214.98C314.741 218.941 313.361 221.76 312.323 223.322C313.113 221.57 314.256 218.646 314.951 214.694C315.608 210.771 315.77 205.647 313.942 200.267C312.123 194.896 308.752 190.477 305.59 187.411C302.428 184.278 299.533 182.269 297.772 180.936C299.629 182.021 302.647 183.821 305.971 186.897C309.285 189.897 312.903 194.458 314.808 200.096Z"
        fill="#6C793C"
      />
      <path
        d="M318.16 226.15C317.941 228.074 315.741 229.836 313.818 229.617C311.894 229.398 309.713 227.15 309.923 225.227C310.142 223.303 312.77 221.589 314.694 221.808C316.617 222.027 318.379 224.227 318.16 226.15Z"
        fill="#86593D"
      />
      <path
        d="M260.423 208.732C268.536 220.769 271.05 236.549 272.85 250.671C273.907 258.947 274.707 267.298 274.917 275.65C274.917 275.65 274.917 275.659 274.917 275.669C274.917 275.669 274.917 275.678 274.917 275.688C274.917 276.002 275.079 276.193 275.288 276.288C278.012 278.526 281.269 278.602 284.63 277.678C284.982 277.583 285.154 277.192 285.144 276.859C284.221 252.5 279.421 226.226 264.927 206.056C264.661 205.675 264.213 205.647 263.823 205.856C262.756 206.447 261.68 207.037 260.613 207.628C260.232 207.837 260.194 208.399 260.413 208.732H260.423Z"
        fill="#9B693C"
      />
      <mask
        id="mask1_5170_218112"
        maskUnits="userSpaceOnUse"
        x="260"
        y="205"
        width="26"
        height="74"
      >
        <path
          d="M260.423 208.732C268.536 220.769 271.05 236.549 272.85 250.671C273.907 258.947 274.707 267.298 274.917 275.65C274.917 275.65 274.917 275.659 274.917 275.669C274.917 275.669 274.917 275.678 274.917 275.688C274.917 276.002 275.079 276.193 275.288 276.288C278.012 278.526 281.269 278.602 284.63 277.678C284.982 277.583 285.154 277.192 285.144 276.859C284.221 252.5 279.421 226.226 264.927 206.056C264.661 205.675 264.213 205.647 263.823 205.856C262.756 206.447 261.68 207.037 260.613 207.628C260.232 207.837 260.194 208.399 260.413 208.732H260.423Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_5170_218112)">
        <path
          d="M287.468 265.87C283.392 269.984 279.002 273.745 274.45 277.316C274.126 277.573 273.774 277.05 274.088 276.802C278.602 273.269 282.954 269.536 286.992 265.46C287.278 265.174 287.763 265.584 287.478 265.87H287.468Z"
          fill="#B97E4D"
        />
        <path
          d="M286.182 259.86C281.811 265.345 275.945 269.221 270.774 273.878C270.47 274.154 270.117 273.63 270.412 273.364C275.536 268.736 281.364 264.898 285.706 259.451C285.963 259.136 286.44 259.546 286.182 259.86Z"
          fill="#B97E4D"
        />
        <path
          d="M285.239 252.9C281.011 256.452 277.307 260.557 273.374 264.423C273.088 264.709 272.603 264.299 272.898 264.014C276.859 260.109 280.602 255.967 284.878 252.386C285.192 252.12 285.554 252.643 285.239 252.9Z"
          fill="#B97E4D"
        />
        <path
          d="M282.506 246.625C278.364 249.919 274.65 253.7 270.793 257.319C270.498 257.595 270.012 257.185 270.308 256.909C274.202 253.252 277.955 249.443 282.145 246.11C282.459 245.853 282.821 246.377 282.506 246.625Z"
          fill="#B97E4D"
        />
        <path
          d="M281.659 234.844C278.193 239.567 274.184 243.805 270.098 247.986C269.822 248.271 269.336 247.871 269.622 247.576C273.707 243.396 277.717 239.158 281.183 234.435C281.421 234.111 281.907 234.52 281.669 234.844H281.659Z"
          fill="#B97E4D"
        />
        <path
          d="M278.688 225.102C275.298 230.054 271.241 234.472 267.222 238.9C266.946 239.205 266.47 238.796 266.746 238.491C270.765 234.053 274.812 229.634 278.212 224.692C278.44 224.359 278.926 224.768 278.688 225.102Z"
          fill="#B97E4D"
        />
        <path
          d="M275.05 219.094C272.374 223.179 269.86 227.369 266.689 231.093C266.422 231.407 265.937 230.988 266.213 230.683C269.384 226.95 271.898 222.77 274.574 218.684C274.803 218.342 275.279 218.761 275.06 219.094H275.05Z"
          fill="#B97E4D"
        />
        <path
          d="M272.565 212.647C270.022 216.237 267.603 219.922 265.051 223.512C264.813 223.845 264.337 223.436 264.575 223.103C267.118 219.513 269.536 215.827 272.088 212.237C272.327 211.904 272.803 212.313 272.574 212.647H272.565Z"
          fill="#B97E4D"
        />
        <path
          d="M268.679 206.818C266.632 209.723 264.594 212.637 262.613 215.589C262.385 215.932 261.908 215.513 262.127 215.179C264.108 212.227 266.146 209.313 268.194 206.409C268.432 206.076 268.908 206.485 268.67 206.818H268.679Z"
          fill="#B97E4D"
        />
        <path
          d="M289.392 277.069C282.649 274.193 276.66 269.975 270.308 266.375C269.955 266.175 270.222 265.604 270.584 265.804C276.936 269.403 282.926 273.631 289.668 276.498C290.039 276.66 289.763 277.231 289.392 277.069Z"
          fill="#B97E4D"
        />
        <path
          d="M291.61 270.613C284.23 266.432 277.431 261.375 270.488 256.519C270.155 256.281 270.565 255.805 270.898 256.033C277.802 260.852 284.554 265.88 291.886 270.032C292.239 270.232 291.963 270.803 291.61 270.603V270.613Z"
          fill="#B97E4D"
        />
        <path
          d="M290.725 261.69C282.659 259.567 276.298 254.338 269.394 249.939C269.051 249.72 269.317 249.148 269.66 249.367C276.507 253.729 282.84 258.957 290.83 261.071C291.22 261.176 291.106 261.795 290.715 261.69H290.725Z"
          fill="#B97E4D"
        />
        <path
          d="M288.373 253.966C281.221 250.252 274.326 246.1 267.365 242.072C267.013 241.872 267.289 241.301 267.641 241.501C274.612 245.539 281.507 249.69 288.649 253.395C289.011 253.585 288.735 254.157 288.373 253.966Z"
          fill="#B97E4D"
        />
        <path
          d="M287.411 247.377C279.726 242.587 272.031 237.807 264.242 233.169C263.899 232.969 264.165 232.388 264.518 232.598C272.298 237.235 279.993 242.016 287.687 246.806C288.03 247.015 287.763 247.596 287.411 247.377Z"
          fill="#B97E4D"
        />
        <path
          d="M284.611 237.873C278.421 234.797 272.308 231.56 266.175 228.369C265.813 228.179 266.089 227.608 266.451 227.798C272.584 230.998 278.697 234.226 284.887 237.311C285.249 237.492 284.983 238.064 284.611 237.883V237.873Z"
          fill="#B97E4D"
        />
        <path
          d="M282.002 229.692C276.002 226.378 269.574 223.997 263.413 221.016C263.051 220.836 263.318 220.264 263.689 220.445C269.85 223.426 276.278 225.807 282.268 229.12C282.621 229.32 282.354 229.892 281.992 229.692H282.002Z"
          fill="#B97E4D"
        />
        <path
          d="M278.345 220.703C272.517 218.979 266.86 216.751 261.099 214.827C260.709 214.694 260.994 214.132 261.375 214.256C267.079 216.17 272.679 218.379 278.459 220.084C278.84 220.198 278.735 220.827 278.345 220.703Z"
          fill="#B97E4D"
        />
        <path
          d="M276.364 214.989C270.07 214.399 264.108 212.408 258.071 210.647C257.68 210.532 257.795 209.913 258.185 210.028C264.223 211.789 270.184 213.78 276.478 214.37C276.888 214.408 276.774 215.027 276.364 214.989Z"
          fill="#B97E4D"
        />
      </g>
      <path
        d="M253.138 208.343C252.747 210.543 254.614 213.228 256.814 213.619C259.013 214.009 262.185 212.228 262.575 210.029C262.965 207.829 260.613 205.058 258.413 204.667C256.214 204.277 253.528 206.143 253.138 208.343Z"
        fill="#6B4C34"
      />
      <path
        d="M266.127 211.466C265.737 213.666 267.603 216.351 269.803 216.742C272.003 217.132 275.174 215.351 275.574 213.151C275.964 210.952 273.612 208.18 271.412 207.78C269.212 207.39 266.527 209.257 266.137 211.456L266.127 211.466Z"
        fill="#6B4C34"
      />
      <path
        d="M236.035 195.706C234.635 197.182 233.892 199.21 233.502 201.096C229.054 201.248 224.617 201.81 220.493 202.734C220.312 201.391 220.274 199.877 220.608 198.41C220.608 198.41 218.732 200.382 217.436 203.505C212.98 204.753 209.056 206.448 206.142 208.514C207.047 198.687 211.037 190.049 218.732 185.888C220.836 187.735 221.75 189.411 221.75 189.411C221.855 187.926 221.408 186.373 220.741 184.945C225.436 183.04 231.311 182.64 238.501 184.355C239.415 186.059 240.282 188.107 240.863 190.459C240.863 190.459 241.691 188.297 242.025 185.393C253.109 189.554 263.765 202.267 264.613 212.133C260.461 204.429 248.624 201.105 236.244 201.058C235.52 198.001 236.035 195.706 236.035 195.706Z"
        fill="#9CB560"
      />
      <path
        d="M242.424 192.106C245.796 192.934 248.843 194.448 251.433 196.201C254.033 197.962 256.214 199.962 257.985 201.943C261.527 205.923 263.613 209.704 264.613 212.142C263.27 209.875 260.947 206.314 257.318 202.571C253.7 198.886 248.586 194.963 242.11 193.334C235.644 191.706 229.273 192.706 224.398 194.42C219.455 196.096 215.798 198.267 213.465 199.495C215.598 197.953 219.112 195.496 224.093 193.563C228.988 191.591 235.635 190.382 242.424 192.106Z"
        fill="#8A9C4E"
      />
      <path
        d="M237.044 181.841C234.959 182.698 233.435 184.317 232.34 185.945C227.255 184.383 222.027 183.212 217.046 182.507C217.294 181.174 217.77 179.746 218.646 178.479C218.646 178.479 215.846 179.612 213.323 182.06C207.856 181.536 202.828 181.622 198.819 182.45C203.171 173.546 210.618 166.937 220.75 165.976C222.522 168.518 222.979 170.451 222.979 170.451C223.607 169.099 223.617 167.461 223.35 165.861C229.312 165.861 236.111 167.747 243.682 172.108C244.148 174.07 244.434 176.327 244.291 178.765C244.291 178.765 245.977 177.051 247.329 174.432C258.471 182.603 266.251 198.649 263.87 208.267C261.775 199.43 249.481 191.754 235.473 186.964C235.682 183.812 237.044 181.85 237.044 181.85V181.841Z"
        fill="#9CB560"
      />
      <path
        d="M245.5 180.897C249.053 182.973 251.986 185.564 254.328 188.211C256.68 190.858 258.471 193.582 259.804 196.125C262.461 201.238 263.546 205.581 263.861 208.266C263.108 205.619 261.68 201.381 258.833 196.458C255.985 191.601 251.519 185.945 244.72 181.935C237.949 177.917 230.388 176.422 224.283 176.174C218.113 175.86 213.237 176.498 210.171 176.755C213.113 176.126 217.932 175.146 224.226 175.241C230.435 175.26 238.377 176.669 245.491 180.888L245.5 180.897Z"
        fill="#8A9C4E"
      />
      <path
        d="M234.368 218.742C234.016 220.57 234.444 222.351 235.073 223.818C231.683 226.703 228.502 229.846 225.76 233.017C224.95 232.264 224.169 231.303 223.693 230.15C223.693 230.15 223.217 232.598 223.75 235.436C220.903 239.035 218.684 242.587 217.446 245.749C213.256 238.826 212.075 230.722 215.999 223.218C218.56 223.094 220.112 223.608 220.112 223.608C219.465 222.58 218.332 221.856 217.103 221.342C219.808 217.171 224.198 213.228 230.645 209.838C232.206 210.371 233.902 211.152 235.52 212.314C235.52 212.314 235.092 210.39 233.902 208.305C244.605 204.058 259.232 205.619 264.813 211.485C257.756 209.095 246.862 214.352 237.197 222.075C235.101 220.551 234.368 218.742 234.368 218.742Z"
        fill="#9CB560"
      />
      <path
        d="M237.558 212.41C240.606 210.829 243.729 209.905 246.615 209.42C249.519 208.934 252.205 208.867 254.576 209.039C259.318 209.4 262.823 210.543 264.813 211.496C262.642 210.867 259.052 210.019 254.366 209.858C249.719 209.734 243.777 210.391 237.93 213.4C232.083 216.39 227.616 221.028 224.674 225.189C221.655 229.37 219.884 233.065 218.674 235.322C219.57 232.989 221.084 229.189 223.998 224.828C226.826 220.485 231.407 215.543 237.549 212.41H237.558Z"
        fill="#8A9C4E"
      />
      <path
        d="M294.572 213.047C295.305 214.761 295.258 216.589 294.953 218.16C298.876 220.265 302.647 222.665 306 225.198C306.638 224.284 307.19 223.189 307.409 221.951C307.409 221.951 308.399 224.246 308.466 227.131C311.999 230.045 314.923 233.054 316.799 235.892C319.436 228.236 318.894 220.075 313.466 213.551C310.942 213.97 309.533 214.799 309.533 214.799C309.952 213.656 310.904 212.713 311.999 211.952C308.475 208.438 303.362 205.514 296.334 203.552C294.915 204.4 293.429 205.524 292.087 207C292.087 207 292.096 205.028 292.82 202.733C281.459 200.838 267.489 205.438 263.27 212.352C269.67 208.523 281.421 211.38 292.496 216.884C294.21 214.961 294.553 213.028 294.553 213.028L294.572 213.047Z"
        fill="#9CB560"
      />
      <path
        d="M290.134 207.523C286.82 206.619 283.573 206.371 280.65 206.504C277.707 206.638 275.069 207.142 272.793 207.809C268.241 209.161 265.061 211.018 263.299 212.37C265.289 211.294 268.613 209.714 273.174 208.571C277.697 207.476 283.64 206.866 289.992 208.571C296.343 210.266 301.686 213.856 305.428 217.313C309.256 220.76 311.77 223.998 313.427 225.96C312.056 223.865 309.78 220.474 306.009 216.827C302.333 213.18 296.81 209.314 290.144 207.542L290.134 207.523Z"
        fill="#8A9C4E"
      />
      <path
        d="M292.667 197.811C293.962 199.373 294.572 201.439 294.848 203.353C299.276 203.791 303.676 204.639 307.714 205.829C307.98 204.506 308.114 203.001 307.875 201.506C307.875 201.506 309.618 203.591 310.713 206.791C315.075 208.324 318.884 210.267 321.665 212.524C321.398 202.658 317.97 193.783 310.561 189.136C308.333 190.84 307.314 192.459 307.314 192.459C307.314 190.973 307.856 189.45 308.609 188.069C304.047 185.86 298.2 185.088 290.915 186.326C289.887 187.974 288.896 189.955 288.173 192.269C288.173 192.269 287.477 190.059 287.335 187.136C276.012 190.573 264.556 202.572 263.07 212.371C267.708 204.953 279.735 202.401 292.096 203.144C293.02 200.144 292.648 197.82 292.648 197.82L292.667 197.811Z"
        fill="#9CB560"
      />
      <path
        d="M286.525 193.812C283.097 194.421 279.964 195.735 277.259 197.316C274.555 198.906 272.25 200.763 270.355 202.62C266.565 206.372 264.251 210 263.08 212.372C264.565 210.191 267.117 206.801 270.974 203.287C274.821 199.84 280.173 196.249 286.744 195.059C293.296 193.85 299.591 195.259 304.342 197.287C309.171 199.278 312.675 201.677 314.932 203.058C312.904 201.382 309.551 198.697 304.714 196.459C299.952 194.173 293.401 192.545 286.516 193.821L286.525 193.812Z"
        fill="#8A9C4E"
      />
      <path
        d="M277.574 182.012C279.421 181.897 281.097 182.536 282.449 183.335C285.773 180.374 289.315 177.65 292.829 175.355C292.21 174.47 291.382 173.584 290.315 172.975C290.315 172.975 292.791 172.803 295.486 173.689C299.429 171.346 303.228 169.613 306.523 168.794C300.343 163.833 292.582 161.661 284.649 164.566C284.154 167.061 284.43 168.642 284.43 168.642C283.516 167.87 282.973 166.68 282.659 165.413C278.174 167.546 273.669 171.356 269.403 177.25C269.689 178.85 270.212 180.602 271.117 182.336C271.117 182.336 269.298 181.678 267.422 180.25C261.708 190.211 261.089 204.743 266.022 210.933C264.708 203.714 271.46 193.706 280.431 185.192C279.24 182.955 277.574 182.002 277.574 182.002V182.012Z"
        fill="#9CB560"
      />
      <path
        d="M270.898 184.355C268.908 187.145 267.546 190.088 266.641 192.859C265.737 195.64 265.279 198.268 265.108 200.61C264.775 205.305 265.384 208.867 266.013 210.952C265.708 208.743 265.413 205.134 265.937 200.515C266.489 195.944 268.003 190.202 271.812 184.841C275.593 179.479 280.792 175.68 285.306 173.318C289.839 170.88 293.715 169.604 296.105 168.699C293.686 169.29 289.744 170.299 285.049 172.613C280.383 174.851 274.869 178.727 270.888 184.355H270.898Z"
        fill="#8A9C4E"
      />
      <path
        d="M258.604 212.171C258.214 214.371 260.08 217.056 262.28 217.447C264.48 217.837 267.651 216.057 268.051 213.857C268.441 211.657 266.089 208.886 263.88 208.495C261.68 208.105 258.995 209.971 258.604 212.171Z"
        fill="#86593D"
      />
      <path
        d="M249.262 306.676C241.958 304.467 238.796 302.257 237.377 301.105C236.682 300.495 236.349 300.181 236.197 300.01L236.054 299.838C234.263 298.039 233.959 296.067 234.054 294.829C234.054 294.191 234.197 293.734 234.254 293.41C234.311 293.087 234.311 292.915 234.273 292.896C234.235 292.877 234.14 293.02 233.987 293.325C233.844 293.629 233.606 294.087 233.511 294.772C233.473 294.944 233.444 295.125 233.406 295.315C233.406 295.505 233.406 295.715 233.406 295.934C233.406 296.153 233.387 296.382 233.425 296.62C233.473 296.858 233.521 297.105 233.568 297.362C233.825 298.372 234.359 299.524 235.282 300.553L235.444 300.743C235.606 300.934 235.978 301.276 236.72 301.933C238.244 303.171 241.568 305.457 248.967 307.695C252.662 308.809 255.48 309.409 257.604 309.856C259.718 310.295 261.147 310.533 262.089 310.704L263.984 311.018C269.212 311.694 271.86 311.723 271.879 311.552C271.898 311.352 269.298 310.942 264.156 309.999L262.27 309.685C261.328 309.514 259.918 309.276 257.814 308.838C255.719 308.399 252.909 307.79 249.272 306.695L249.262 306.676Z"
        fill="#00AFAA"
      />
      <path
        d="M307.066 309.332C297.876 311.313 293.125 311.408 290.791 311.579L288.449 311.694C282.011 311.97 278.783 311.637 278.764 311.875C278.754 311.97 279.554 312.132 281.164 312.351C282.783 312.551 285.221 312.722 288.477 312.732L290.849 312.617C293.21 312.446 298 312.351 307.295 310.351C316.551 308.151 320.846 305.875 322.969 304.818L325.064 303.675C327.816 301.876 329.683 300.238 330.769 299.019C331.826 297.752 332.302 297.105 332.226 297.047C331.997 296.828 330.083 299.571 324.531 302.79L322.484 303.904C320.417 304.932 316.208 307.17 307.057 309.342L307.066 309.332Z"
        fill="#00AFAA"
      />
      <path
        d="M316.532 312.295C313.304 313.533 311.589 313.8 310.742 313.933C309.894 314.076 309.894 314.057 309.894 314.057C307.59 314.457 306.38 314.409 306.361 314.628C306.333 314.781 307.542 315.209 310.018 315.085C310.018 315.085 310.018 315.104 310.904 314.952C311.789 314.809 313.58 314.533 316.903 313.257C320.198 311.924 321.722 311 322.493 310.571L323.264 310.133C325.283 308.762 326.131 307.867 326.026 307.724C325.912 307.562 324.845 308.143 322.741 309.238L321.988 309.667C321.227 310.076 319.741 310.99 316.532 312.285V312.295Z"
        fill="#00AFAA"
      />
      <path
        d="M255.804 315.293C254.538 315.083 253.595 314.893 252.89 314.722C252.186 314.579 251.728 314.445 251.414 314.35L250.795 314.179C249.081 313.807 248.205 313.626 248.119 313.817C248.053 313.969 248.805 314.502 250.5 315.169L251.138 315.35C251.452 315.445 251.928 315.579 252.652 315.731C253.376 315.902 254.338 316.093 255.623 316.312C258.204 316.712 259.499 316.836 260.147 316.921L260.794 316.998C264.346 317.14 264.423 316.683 260.918 315.969L260.28 315.893C259.642 315.807 258.347 315.683 255.804 315.293Z"
        fill="#00AFAA"
      />
      <path
        d="M330.616 290.239L329.654 289.239C328.788 288.754 328.464 289.097 328.911 289.963L329.854 290.935L330.683 291.963C331.473 292.496 331.902 292.277 331.511 291.344L330.607 290.239H330.616Z"
        fill="#00AFAA"
      />
      <path
        d="M60.1562 283.335C59.7086 283.268 59.2896 283.183 58.8706 283.135C58.4421 283.116 58.0326 283.087 57.6422 283.068C56.8327 283.145 56.0709 283.183 55.4043 283.421C54.052 283.792 53.0331 284.354 52.157 284.782C51.2904 285.24 50.5762 285.573 49.9857 285.849C49.3763 286.059 48.9001 286.287 48.5002 286.344C48.1002 286.42 47.805 286.478 47.586 286.525C47.3574 286.535 47.2146 286.544 47.1098 286.554H46.9098C44.691 286.582 42.9674 285.925 41.8341 285.649C40.6914 285.325 40.0914 285.23 40.0629 285.316C40.0343 285.411 40.5581 285.678 41.6437 286.173C42.1865 286.411 42.8817 286.697 43.7387 286.992C44.6053 287.268 45.6433 287.563 46.9194 287.611H47.1479C47.2622 287.592 47.4431 287.582 47.7002 287.563C47.9574 287.516 48.2907 287.449 48.7478 287.363C49.2049 287.297 49.7191 287.049 50.3857 286.82C51.0142 286.535 51.7665 286.173 52.6236 285.725C54.3472 284.878 56.6137 283.573 59.9562 284.363C61.1275 284.611 62.3084 284.954 63.5178 285.278C64.7367 285.592 66.0033 285.811 67.3079 285.84H67.2698C69.4315 285.992 71.5932 285.935 73.7168 285.963C78.4116 285.887 80.6971 286.611 80.7638 286.354C80.7828 286.268 80.2305 286.04 79.0782 285.687C77.9164 285.392 76.1261 285.021 73.7359 284.925C71.5742 284.887 69.4506 284.954 67.346 284.802H67.3079C66.1366 284.773 64.9557 284.573 63.7749 284.268C62.5941 283.954 61.4037 283.602 60.1562 283.335Z"
        fill="#00AFAA"
      />
      <path
        d="M185.878 238.53C183.973 238.33 182.373 238.615 181.002 238.901C179.64 239.215 178.488 239.558 177.526 239.863C175.602 240.472 174.326 240.777 173.469 240.939C172.612 241.053 172.193 241.101 171.974 241.082H171.765C171.174 241.149 170.641 241.044 170.136 241.015C169.651 240.882 169.165 240.834 168.755 240.663C168.346 240.491 167.955 240.387 167.632 240.206C167.298 240.034 167.003 239.872 166.737 239.73C165.727 239.082 165.203 238.73 165.137 238.815C165.08 238.873 165.47 239.368 166.432 240.187C166.689 240.368 166.984 240.577 167.308 240.806C167.632 241.044 168.051 241.196 168.479 241.415C168.908 241.644 169.431 241.739 169.974 241.92C170.536 241.996 171.136 242.139 171.784 242.11H172.022C172.26 242.11 172.726 242.072 173.65 241.948C174.564 241.787 175.907 241.453 177.831 240.844C178.793 240.539 179.907 240.206 181.221 239.901C182.525 239.625 184.078 239.358 185.744 239.549C187.401 239.796 188.83 240.463 190.096 240.987C191.363 241.529 192.505 241.948 193.534 242.196C194.562 242.425 195.458 242.51 196.2 242.491C196.943 242.453 197.524 242.367 197.981 242.263C198.895 242.072 199.324 241.929 199.543 241.872L199.762 241.806C200.943 241.415 201.933 241.091 202.809 240.92C203.676 240.749 204.419 240.701 205.009 240.739C206.199 240.815 206.79 241.015 206.828 240.901C206.856 240.844 206.333 240.463 205.076 240.187C204.447 240.053 203.638 240.015 202.685 240.111C201.733 240.215 200.657 240.491 199.476 240.796L199.267 240.853C199.057 240.91 198.629 241.044 197.781 241.225C196.934 241.396 195.667 241.606 193.782 241.168C192.839 240.949 191.763 240.558 190.506 240.015C189.239 239.501 187.782 238.787 185.887 238.501L185.878 238.53Z"
        fill="#00AFAA"
      />
      <path
        d="M142.282 277.202C139.044 278.059 136.682 277.859 134.797 277.63C132.93 277.468 131.664 277.287 130.807 277.354C129.093 277.373 129.093 277.392 129.093 277.392C128.512 277.487 127.969 277.582 127.455 277.668C126.95 277.782 126.502 277.925 126.083 278.049C125.245 278.287 124.588 278.554 124.045 278.744C122.969 279.097 122.436 279.325 122.455 279.42C122.474 279.516 123.046 279.468 124.198 279.277C124.76 279.173 125.464 278.992 126.293 278.839C126.712 278.763 127.15 278.659 127.636 278.592C128.121 278.544 128.635 278.497 129.188 278.44C129.188 278.44 129.188 278.439 130.807 278.411C131.616 278.344 132.835 278.525 134.711 278.678C136.53 278.916 139.273 279.106 142.52 278.23C144.139 277.849 145.529 277.64 146.758 277.64C147.976 277.64 149.072 277.83 149.995 277.925C151.843 278.125 153.09 278.325 153.909 278.43L155.566 278.649C156.709 278.735 157.718 278.811 158.575 278.878C159.442 278.906 160.166 278.878 160.737 278.868C161.889 278.83 162.461 278.763 162.461 278.668C162.461 278.573 161.889 278.449 160.756 278.316C160.194 278.249 159.49 278.173 158.642 278.078C157.795 277.944 156.814 277.792 155.69 277.621L154.043 277.401C153.214 277.297 151.986 277.097 150.1 276.887C149.157 276.792 148.091 276.602 146.748 276.602C145.424 276.602 143.91 276.84 142.282 277.221V277.202Z"
        fill="#00AFAA"
      />
      <path
        d="M194.82 306.257C193.21 306.152 191.782 306.257 190.572 306.437C189.363 306.609 188.335 306.809 187.468 306.971C185.725 307.304 184.582 307.514 183.821 307.59C183.059 307.694 182.668 307.694 182.478 307.714H182.287C180.192 307.904 178.631 307.637 177.602 307.39C176.574 307.133 176.05 306.971 176.012 307.066C175.974 307.142 176.421 307.466 177.44 307.913C178.45 308.342 180.116 308.799 182.345 308.761H182.545C182.745 308.723 183.154 308.732 183.954 308.618C184.754 308.542 185.935 308.323 187.677 307.99C189.411 307.694 191.715 307.104 194.772 307.285C196.315 307.361 197.6 307.647 198.743 307.98C199.886 308.313 200.895 308.618 201.771 308.828C203.543 309.285 204.819 309.218 205.619 309.228C206.428 309.171 206.828 309.151 207.028 309.123L207.228 309.104C209.399 308.761 210.932 308.418 211.999 308.371C213.056 308.294 213.599 308.332 213.618 308.218C213.637 308.152 213.113 307.923 212.008 307.828C210.904 307.694 209.237 307.885 207.123 308.085C207.123 308.085 207.123 308.113 205.59 308.209C204.828 308.209 203.695 308.266 202.019 307.837C201.181 307.628 200.2 307.342 199.029 306.999C197.857 306.656 196.448 306.352 194.829 306.266L194.82 306.257Z"
        fill="#00AFAA"
      />
      <path
        d="M217.57 271.097C213.332 271.697 211.247 271.85 210.199 271.84C209.151 271.869 209.151 271.84 209.151 271.84C206.285 271.888 204.847 271.583 204.79 271.812C204.742 271.945 206.123 272.631 209.123 272.878C209.123 272.878 209.123 272.907 210.209 272.878C211.294 272.878 213.475 272.726 217.703 272.126C221.922 271.535 224.017 271.612 225.064 271.612L226.112 271.678C228.988 271.755 230.435 272.04 230.483 271.821C230.521 271.688 229.15 271.021 226.178 270.65L225.093 270.583C224.017 270.583 221.807 270.507 217.56 271.107L217.57 271.097Z"
        fill="#00AFAA"
      />
      <path
        d="M361.032 271.641C357.185 272.526 355.28 272.393 354.338 272.288C353.862 272.25 353.633 272.174 353.519 272.165L353.404 272.145C352.747 272.088 352.205 271.936 351.719 271.822C351.224 271.736 350.843 271.584 350.519 271.488C349.881 271.298 349.529 271.241 349.481 271.327C349.433 271.412 349.7 271.631 350.3 271.993C350.595 272.174 350.967 272.412 351.462 272.584C351.947 272.784 352.519 273.022 353.214 273.155L353.338 273.174C353.462 273.183 353.719 273.269 354.223 273.307C355.233 273.422 357.356 273.545 361.242 272.641C365.099 271.726 367.003 271.707 367.965 271.688L368.917 271.736C369.574 271.717 370.146 271.803 370.641 271.841C371.136 271.86 371.546 271.955 371.879 271.993C372.545 272.088 372.898 272.088 372.926 271.993C372.955 271.907 372.66 271.726 372.022 271.46C371.698 271.327 371.298 271.155 370.784 271.05C370.279 270.936 369.679 270.755 368.984 270.708L367.965 270.65C366.956 270.65 364.87 270.708 361.013 271.622L361.032 271.641Z"
        fill="#00AFAA"
      />
      <path
        d="M288.744 349.605C285.506 349.682 283.23 350.377 281.545 350.796C280.707 350.996 280.002 351.167 279.421 351.31C278.84 351.405 278.373 351.472 278.002 351.539C277.259 351.681 276.878 351.596 276.697 351.634H276.507C275.479 351.767 274.583 351.615 273.803 351.577C273.012 351.567 272.384 351.358 271.869 351.282C270.831 351.091 270.289 351.043 270.26 351.139C270.231 351.224 270.717 351.453 271.727 351.824C272.241 351.986 272.86 352.281 273.679 352.377C274.488 352.5 275.421 352.739 276.536 352.681H276.736C276.936 352.643 277.345 352.719 278.135 352.577C278.526 352.51 279.021 352.434 279.64 352.339C280.23 352.196 280.954 352.024 281.811 351.815C283.487 351.396 285.754 350.729 288.715 350.653C290.182 350.701 291.372 351.177 292.467 351.662C293.572 352.158 294.629 352.434 295.496 352.615C297.238 352.938 298.381 353.215 299.172 353.243L300.743 353.396C302.895 353.453 304.504 353.377 305.561 353.281C306.628 353.196 307.161 353.1 307.152 353.005C307.152 352.91 306.618 352.815 305.561 352.729C304.495 352.643 302.905 352.567 300.809 352.367L299.286 352.224C298.524 352.196 297.4 351.92 295.705 351.615C294.867 351.434 293.934 351.205 292.867 350.72C291.801 350.244 290.41 349.682 288.753 349.634L288.744 349.605Z"
        fill="#00AFAA"
      />
      <path
        d="M335.835 335.368C333.483 335.663 331.768 336.139 330.502 336.425C329.226 336.729 328.388 336.872 327.826 336.977L326.693 337.12C325.15 337.339 323.969 337.405 323.169 337.424C322.379 337.463 321.969 337.529 321.96 337.624C321.95 337.72 322.341 337.834 323.141 337.977C323.941 338.139 325.16 338.234 326.798 338.148L327.978 337.996C328.559 337.891 329.445 337.739 330.73 337.434C332.026 337.139 333.683 336.682 335.968 336.396C338.254 336.072 339.911 336.177 341.206 336.367C342.491 336.539 343.339 336.691 343.92 336.796L345.062 337.005C348.253 337.463 349.9 337.329 349.9 337.158C349.9 336.958 348.348 336.72 345.243 335.987L344.091 335.777C343.52 335.672 342.653 335.51 341.329 335.339C340.034 335.139 338.158 335.025 335.825 335.368H335.835Z"
        fill="#00AFAA"
      />
      <path
        d="M134.74 321.075C130.073 321.866 127.75 321.799 126.607 321.646C126.026 321.618 125.75 321.57 125.607 321.532L125.464 321.504C125.074 321.446 124.702 321.399 124.36 321.351C124.017 321.285 123.712 321.199 123.426 321.132C123.131 321.066 122.865 321.008 122.626 320.951C122.388 320.875 122.179 320.799 121.988 320.732C121.227 320.485 120.817 320.399 120.769 320.475C120.722 320.561 121.046 320.808 121.769 321.237C121.95 321.342 122.15 321.466 122.388 321.58C122.636 321.675 122.903 321.78 123.188 321.894C123.483 321.999 123.798 322.132 124.15 322.246C124.512 322.332 124.893 322.418 125.302 322.513L125.455 322.542C125.607 322.58 125.902 322.627 126.512 322.665C127.721 322.827 130.197 322.884 134.901 322.085C139.587 321.256 141.901 321.037 143.063 321.037C143.643 320.999 143.929 321.018 144.082 321.027H144.224C147.405 320.97 148.995 321.418 149.052 321.17C149.1 321.056 147.576 320.228 144.243 319.999H144.091C143.939 319.999 143.634 319.97 143.034 319.999C141.825 319.999 139.406 320.237 134.74 321.056V321.075Z"
        fill="#00AFAA"
      />
      <path
        d="M211.704 358.49C211.646 351.062 197.572 349.262 186.144 351.405C174.717 353.547 179.164 366.879 194.715 366.565C210.266 366.251 211.732 362.442 211.704 358.49Z"
        fill="#56D2CE"
      />
      <path
        d="M393.42 310.38C393.382 302.952 383.944 301.153 376.288 303.295C368.632 305.438 371.603 318.77 382.03 318.456C392.458 318.142 393.439 314.332 393.42 310.38Z"
        fill="#56D2CE"
      />
      <path
        d="M109.294 238.283C109.228 230.855 93.4387 229.055 80.6209 231.198C67.8031 233.34 72.7835 246.672 90.239 246.358C107.694 246.044 109.342 242.235 109.304 238.283H109.294Z"
        fill="#56D2CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_5170_218112">
        <rect width="400" height="400" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IllustrationFallback;
