const AboutContent = () => {
  return (
    <>
      {/* <h3 className="mb-4">About</h3> */}

      <div className="textBlackColor">
        <div className="mb-6">
          <h4 className="text-2xl mb-4 font-medium ">About ASC&apos; s Greenhouse Gas Calculators</h4>
          <p>The Aquaculture Stewardship Council
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              // rel="noreferrer"
              href="https://asc-aqua.org/about-asc/"
              target="_blank"
              className="text-primary-500 font-medium"
            >
              {" "} (https://asc-aqua.org/about-asc/){" "}
            </a>developed this tool to support the calculation, tracking, and reduction of greenhouse gas (GHG) emissions
            from the production of aquaculture feeds and farmed seafood products. Producers are required to
            calculate and report GHG emissions under ASC&apos;s Feed Standard and in ASC&apos;s draft Farm Standard.
            These calculators will help producers estimate and characterise their GHG emissions in line with
            the methodological requirements of those Standards and will improve the transparency, consistency,
            completeness, relevance, and accuracy of GHG reporting by ASC certified producers. The scope, data
            sources and calculations used in these calculators reflect methodological guidance provided by
            ASC&apos;s Energy Use and Greenhouse Gas Emissions Technical Working Group of life cycle assessment
            experts and stakeholder representatives.
          </p>
        </div>
        <div className="mb-6">
          <h4 className="text-2xl mb-4 font-medium">Intended applications</h4>
          <p className="mb-3">The structure, scope and methods of these calculators were developed primarily to help producers
            satisfy the GHG requirements of ASC&apos;s Feed and Farm Standards. Specifically, the calculators are intended to:
          </p>

          <ul className="customList">
            <li>help producers calculate GHG emissions in line with ASC Standards’ requirements</li>
            <li>provide necessary information in the right format to report GHG emissions to ASC</li>
            <li>identify key ‘hot spots’ and drivers of GHG emissions in aquaculture supply chains</li>
            <li>inform internal business decision-making and management strategies relating to energy use and GHG emissions</li>
            <li>connect feed, hatchery, and grow-out producers in a way that maintains confidentiality of input data while facilitating complete and robust GHG accounting across the supply chain</li>
            <li>help third-party auditors verify calculations and data inputs to ensure conformance with the methodological requirements of ASC’s Standards</li>
          </ul>

          <p>These calculators are not intended to satisfy GHG accounting requirements of other standards or initiatives or to compare
            GHG emissions with those produced by other organisations or using other tools. ASC has worked to promote broad consistency with
            available standards and best practices, but differences in scope, methods and underlying data sources must always be considered
            when comparing the GHG performance of different products or production systems.</p>
        </div>

        <div className="mb-6">
          <h4 className="text-2xl mb-4 font-medium">Terms of use</h4>
          <p className="mb-3">Users of ASC&apos;s Greenhouse Gas Calculators can freely access the calculators to build, save, share,
            and download results from GHG models. ASC makes no guarantees related to the use of results from these
            calculators beyond the intended applications above. Specifically, no guarantees are made that results
            conform with any particular external standards or support specific environmental product declarations,
            carbon footprint labels, or regulatory compliance.</p>
          <p className="mb-3">Data entered into the calculators by users are not viewed, extracted, or shared by ASC unless
            requested by users to assist with questions, bugs, or errors. Data from the calculators that are
            subsequently submitted to ASC to satisfy Standard requirements are maintained and used by ASC in
            accordance with ASC&apos;s data submission procedures and data sharing policies.</p>
          <p className="mb-3">Users agree not to share access to the calculators with third parties and not to download,
            share, duplicate, or distribute the calculators, underlying data, or written software.</p>
          <p>Any personal data, such as names and email addresses, stored in the calculators are maintained
            and protected in accordance with GDPR regulations following ASC&apos;s Data Protection Policy:
            <a
              rel="noreferrer"
              href="https://www.asc-aqua.org/wp-content/uploads/2022/01/ASC-GDPR-Data-Protection-Policy-V.1.0-1.pdf"
              target="_blank"
              className="text-primary-500 font-medium"
            >{" "}https://www.asc-aqua.org/wp-content/uploads/2022/01/ASC-GDPR-Data-Protection-Policy-V.1.0-1.pdf{" "}</a></p>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
