import { Navigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../Context/UserContext";
import Loader from "../../components/Loader";
// import AccessDenied from "../../pages/AccessDenied";
import { navBarRoutes } from "../constant";
import { getActiveRouteOnPermissionBasis } from "../../utils/utils";

const PrivateRoute = ({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: string;
}) => {
  const location = useLocation();
  const { user, isAuthenticated, isLoading, userPermissions } = useAuthUser();
  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // if (isAuthenticated && !(user && roles.includes(user?.userType))) {
  //   return <AccessDenied />;
  // }

  // @ts-expect-error:Argument of type 'string' is not assignable to parameter of type 'never'.
  if (user && !userPermissions.includes(permission)) {
    const isValidRoute = getActiveRouteOnPermissionBasis({
      navBarRoutes,
      permissions: userPermissions,
    });
    if (isValidRoute) {
      return <Navigate to={isValidRoute.to} />;
    }
  }

  return children;
};

export default PrivateRoute;
