import { PERMISSIONS } from "./permissions";

export const navBarRoutes = [
  {
    name: "feed-calculator",
    value: "Feed Calculator",
    to: "/feed-calculator",
    permission: PERMISSIONS.FEED_CALCULATOR_CANVIEW,
  },
  {
    name: "farm-calculator",
    value: "Farm Calculator",
    to: "/farm-calculator",
    permission: PERMISSIONS.FARM_CALCULATOR_CANVIEW,
  },
];

export const validEmailRE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validPasswordRE =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const userTypeSelection: Array<{ value: string; label: string }> = [
  { value: "Feed-supplier", label: "Feed Supplier" },
  { value: "Farm-producer", label: "Farm Producer" },
];

export const FEEDSUPPLIER = "Feed-supplier";
export const FARMPRODUCER = "Farm-producer";

export const STRING_TRIM_REGEX = /^([\s])*[a-zA-Z0-9]+([\s]*[a-zA-Z0-9]+)*([\s])*$/g;

export const CAPITALIZE_REGEX = /([a-z])([A-Z])|\d/g;
