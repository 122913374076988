import { useQuery, useQueryClient } from "react-query";
import { readUserProfile } from "./readUserProfile";

export const queryKeys = {
  all: "user-profile",
};

export function useUserProfile() {
  return useQuery(queryKeys.all, () => readUserProfile());
}

export function useProfileSync() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries(queryKeys.all);
  };
}
