import { FARMPRODUCER, FEEDSUPPLIER } from "../shared/constant";
import {
  getActiveRouteOnPermissionBasisTypes,
  getDefaultImageNameTypes,
} from "./type";

export const getActiveRouteOnPermissionBasis = ({
  navBarRoutes,
  permissions,
}: getActiveRouteOnPermissionBasisTypes) => {
  return navBarRoutes.find((route) =>
    permissions.find((permission: string) => {
      if (permission.toString() === route.permission.toString()) {
        return route;
      }
    })
  );
};

export const getDefaultImageName = ({
  firstName,
  lastName,
}: getDefaultImageNameTypes) => {
  let defaultProfileName = "";
  if (firstName) {
    defaultProfileName += firstName[0];
  }
  if (lastName) {
    defaultProfileName += lastName[0];
  }
  return defaultProfileName.toUpperCase();
};

const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export const DOTS: string = "...";

export const getPages = (totalPages: number, currentpage: number) => {
  if (totalPages < 6) {
    return range(1, totalPages);
  }
  const pages: Array<string | number> = [1, 2];
  if (currentpage > 4) {
    pages.push(DOTS);
  }
  if (currentpage > 1 && currentpage < totalPages) {
    pages.push(
      ...range(
        currentpage - 1 < 3 ? 3 : currentpage - 1,
        currentpage + 1 < totalPages - 1 ? currentpage + 1 : totalPages - 2
      )
    );
  }
  if (currentpage < totalPages - 3) {
    pages.push(DOTS);
  }
  pages.push(totalPages - 1);
  pages.push(totalPages);
  return pages;
};

export const customSelectStyle = {
  className: "customSelectBox",
  styles: {
    control: (baseStyles: object, state: { isFocused: string }) => ({
      ...baseStyles,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#00afaa" : "#ddd",
      boxShadow: state.isFocused
        ? " 0px 0px 0px 3px rgba(0, 175, 170, 0.15)"
        : "",
      outline: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#808080",
        fontSize: "14px",
      };
    },
  },
  theme: (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#d9f3f2",
      primary: "#00afaa",
      color: "#808080",
      fontSize: "14px",
    },
  }),
};

export const customMultiSelectStyle = {
  className: "customSelectBox customCheckbox",
  styles: {
    control: (baseStyles: object, state: { isFocused: string }) => ({
      ...baseStyles,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#00afaa" : "#ddd",
      boxShadow: state.isFocused
        ? " 0px 0px 0px 3px rgba(0, 175, 170, 0.15)"
        : "",
      outline: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#808080",
        fontSize: "14px",
      };
    },
  },
  theme: (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#d9f3f2",
      primary: "#fff",
    },
  }),
};

export const getGridFilters = (id: string) => {
  console.log(id);
  const gridData = sessionStorage.getItem("grid");
  if (gridData) {
    return JSON.parse(gridData);
  }

  return {};
};

export const getUserPermission = (userData: any): string[] | [] => {
  if (userData?.isAdmin) {
    return [
      "ADMIN_PANEL_CANVIEW",
      "FEED_CALCULATOR_CANVIEW",
      "FARM_CALCULATOR_CANVIEW",
      "USER_PROFILE_CANVIEW",
      "SAVE_MODAL_CANVIEW",
      "MILLING_INFORMATION_CANVIEW",
    ];
  }
  else if (userData?.isAuditor) {
    // if (userData?.isAuditor && userData?.userType.includes(FEEDSUPPLIER)) {
    //   return [
    //     "FEED_CALCULATOR_CANVIEW",
    //     "USER_PROFILE_CANVIEW",
    //     "SAVE_MODAL_CANVIEW",
    //     "MILLING_INFORMATION_CANVIEW",
    //   ];
    //   // eslint-disable-next-line no-dupe-else-if
    // } else if (userData?.isAuditor && userData?.userType.includes(FARMPRODUCER)) {
    //   return [
    //     "FARM_CALCULATOR_CANVIEW",
    //     "USER_PROFILE_CANVIEW",
    //     "SAVE_MODAL_CANVIEW",
    //     "MILLING_INFORMATION_CANVIEW",
    //   ];
    // } else if (
    //   // eslint-disable-next-line no-dupe-else-if
    //   userData?.isAuditor &&
    //   userData?.userType.includes(FEEDSUPPLIER) &&
    //   userData?.userType.includes(FARMPRODUCER)
    // ) {
    //   return [
    //     "FEED_CALCULATOR_CANVIEW",
    //     "FARM_CALCULATOR_CANVIEW",
    //     "USER_PROFILE_CANVIEW",
    //     "SAVE_MODAL_CANVIEW",
    //     "MILLING_INFORMATION_CANVIEW",
    //   ];
    // }
    // else {
      return [
        "USER_PROFILE_CANVIEW",
        "SAVE_MODAL_CANVIEW",
      ];
  //  }
  }
  else if (
    userData?.userType.includes(FEEDSUPPLIER) &&
    userData?.userType.includes(FARMPRODUCER)
  ) {
    return [
      "FEED_CALCULATOR_CANVIEW",
      "FARM_CALCULATOR_CANVIEW",
      "USER_PROFILE_CANVIEW",
      "SAVE_MODAL_CANVIEW",
      "MILLING_INFORMATION_CANVIEW",
    ];
  } else if (userData?.userType.includes(FEEDSUPPLIER)) {
    return [
      "FEED_CALCULATOR_CANVIEW",
      "USER_PROFILE_CANVIEW",
      "SAVE_MODAL_CANVIEW",
      "MILLING_INFORMATION_CANVIEW",
    ];
  } else if (userData?.userType.includes(FARMPRODUCER)) {
    return [
      "FARM_CALCULATOR_CANVIEW",
      "USER_PROFILE_CANVIEW",
      "SAVE_MODAL_CANVIEW",
      "MILLING_INFORMATION_CANVIEW",
    ];
  } else {
    return [];
  }
}
export const convertUtilsTimeToDateAndTime = (date: string) => {
  const convertedDate = new Date(date).toLocaleDateString();
  const convertedTime = new Date(date).toLocaleTimeString("en-US", { timeZone: "UTC" });

  return `${convertedDate}, ${convertedTime}`;
};

export const generateRandomNumber = () => {
  return Math.random();
};

export const joinSemiColon = (value: any[]) => {
  return value.join(", ");
};

export const capitalize = (value: string) => {
  return (value && value[0].toUpperCase() + value.slice(1).toLowerCase()) || "";
};

export const disableAuditorRoutes = ["/about-us", "/save-models", "/site-information"]

export const enableLocalStorageValue = ["/site-information", "/farm-calculator", "/feed-calculator"];