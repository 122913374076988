import {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserProfile } from "../../api/readUserData";
import { navBarRoutes } from "../constant";
import Loader from "../../components/Loader";
import {
  getActiveRouteOnPermissionBasis,
  getUserPermission,
} from "../../utils/utils";

// Update type according user after api call...
export const UserAuthContext = createContext({});

// eslint-disable-next-line react/prop-types
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState<string[] | []>([]);
  const { data, isLoading, isSuccess } = useUserProfile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data?.id) {
      const permissions: string[] | [] = getUserPermission(data);
      setUserPermissions(permissions);
      if (
        location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/about" ||
        location.pathname === "/"
      ) {
        if (data?.isAuditor) {
          navigate("/shared-models");
        } else {
          if (data?.isMillCreated) {
            navigate("/save-models");
          } else {
            navigate("/about");
          }
        }
      }
      const validRoute = getActiveRouteOnPermissionBasis({
        navBarRoutes,
        permissions: userPermissions && userPermissions,
      });
      if (validRoute) {
        if (
          data?.data?.userType === "ADMIN" &&
          location.pathname === "/admin/users"
        ) {
          return navigate("/admin/users");
        }
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <UserAuthContext.Provider
      value={{
        user: data ? data : undefined,
        isAuthenticated: isSuccess ? data?.id && true : false,
        isLoading: isLoading,
        userPermissions: userPermissions,
        isAuditor: data && data?.isAuditor,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useAuthUser = () =>
  useContext(UserAuthContext) as {
    user: any;
    isAuthenticated: boolean;
    isLoading: boolean;
    userPermissions: string[] | [];
    isAuditor: boolean;
  };
