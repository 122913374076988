import { toast } from "react-toastify";
import { ErrorHandling } from "./type";

const handleAPIError = (error: ErrorHandling) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    toast.error(error.response.data.error.message);
  } else if(error?.response?.data?.message === "Invalid accesss token") {
    window.location.reload();
  } else {
    toast.error("Something went wrong.");
  }
};
export default handleAPIError;
